window.jQuery = window.$ = require('jquery');
window.isJilSander = true;

let processInclude = require('base/util');

function getHeaderHeight() {
  var root = document.documentElement;
  var headerHeight = $('.desktop-menu-wrapper')[0].clientHeight;
  root.style.setProperty('--header-height-calc', headerHeight + "px");
}


document.addEventListener("DOMContentLoaded", function(event) { 
  processInclude(require('core/components/wishlist'));
  processInclude(require('./login'));
  processInclude(require('tealium/tealiumEvents'));
  processInclude(require('./header-banner'));
  getHeaderHeight();

  setTimeout(() => {
    getHeaderHeight();
  }, 200);
});

function handleFooterAccordionToggle(clickedElement) {
  const $clickedBox = $(clickedElement).closest('.section-footer-box');
  $('.section-footer-box').each(function() {
      if ($(this).is($clickedBox)) {
          // Do nothing for the clicked box
          return;
      }
      $(this).removeClass('footer-accordeon-expanded').addClass('footer-accordeon-condensed');
  });
  if ($clickedBox.hasClass('footer-accordeon-expanded')) {
      $clickedBox.removeClass('footer-accordeon-expanded').addClass('footer-accordeon-condensed');
  } else {
      $clickedBox.removeClass('footer-accordeon-condensed').addClass('footer-accordeon-expanded');
  }
}

$('.button-open-footer').on('click', function() {
  handleFooterAccordionToggle(this);
});
